import React from "react";
import { Col } from "react-bootstrap";
import "./Title.css";

export const Title = (props) => (
  <Col xs={12} className="box-wrapper">
    <div>
      <h1 className="title" style={{ color: `${props.color}` }}>
        {props.children}
      </h1>
      <div
        className="underline"
        style={{ marginBottom: `${props.marginBottom}` }}
      />
    </div>
  </Col>
);
