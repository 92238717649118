import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Header } from "./components/Header";
import { About } from "./components/About";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";

function App() {
  return (
    <React.Fragment>
      <Header />
      <About />
      <Projects />
      <Contact />
    </React.Fragment>
  );
}

export default App;
