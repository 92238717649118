import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Title } from "./framework/Title.js";
import { Card, IconCard } from "./framework/Card.js";
import { MdDesktopWindows, MdCode, MdPhonelink } from "react-icons/md";

export const About = () => (
  <Container
    style={{ paddingTop: "100px", paddingBottom: "120px" }}
    id="scroll"
  >
    <Row style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
      <Title>About</Title>
      <Card xs={12} md={6} title="Hi, I'm Can! ">
        I am a software developer. I have been working mainly as a
        Full-stack/Back-end and Android/Java developer since August 2019. I
        enjoy discovering and working with the new technologies in the industry,
        however I am especially interested in{" "}
        <strong>
          back-end development, software architect duties, mobile app
          development and operating systems.
        </strong>{" "}
        I am a computer science graduate (Bachelor of Science,{" "}
        <a
          href="https://www.jobline.uni-muenchen.de/app_language/degrees_and_grades/grades/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          67%
        </a>
        , Class II Division I - 1.8 in German Grading System) from The
        University of Nottingham. I have worked as a developer for two years,
        after getting my bachelor's degree. After working for two years, I did
        my master's degree on data engineering at Jacobs University Bremen. I
        also kept working as a software developer throughout the duration of my
        studies
        <br />
        <br />I was born in Istanbul, Turkiye. I currently live in Bremen,
        Germany. I have a passion for the guitar, which I've been playing for
        five years. I enjoy reading about general history, especially 20th
        century history. I like to follow up with the advancements on hardware
        and defence industry. Finally I love to travel different countries to
        visit historical sites and experience different cultures.
      </Card>
      <Col xs={12} md={6} className="box-wrapper">
        <Row>
          <Col lg={3} className="d-xs-none" />
          <IconCard
            xs={12}
            lg={6}
            icon={<MdDesktopWindows />}
            title="Programming"
          >
            Java, Python, Dart, C, C++, C#, HTML, CSS, Javascript
          </IconCard>
          <Col lg={3} className="d-xs-none" />
          <IconCard
            xs={12}
            lg={6}
            icon={<MdCode />}
            title="Frameworks"
            color="#880088"
          >
            Pandas, Prometheus, Flutter, React.JS, Airflow, Jetpack
          </IconCard>
          <IconCard
            xs={12}
            lg={6}
            icon={<MdPhonelink />}
            title="Environments"
            color="#AA0000"
          >
            Windows, GNU/Linux, Android, Docker, Maven, Gradle, Git
          </IconCard>
        </Row>
      </Col>
    </Row>
  </Container>
);
