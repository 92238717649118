import React from "react";
import "./Header.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import { HiOutlineChevronDown } from "react-icons/hi";
import pp from "../images/pp.jpg";

export const Header = () => (
  <div className="background">
    <Container className="wrapper">
      <Row>
        <Col xs={3} className="d-md-none" />
        <Col xs={6} md={4}>
          <Image
            src={pp}
            style={{
              width: "100%",
              border: "2px solid #1c2e4a",
              marginBottom: "1rem",
            }}
            roundedCircle
          />
        </Col>
        <Col xs={3} className="d-md-none" />
        <Col
          xs={12}
          md={8}
          className="box-wrapper"
          style={{
            textAlign: "center",
          }}
        >
          <div>
            <h1 className="title-main title-desktop title-tablet">
              Hi, I'm Can Yetismis
            </h1>
            <h2 className="title-sub">
              I'm a Software Developer and a Data Engineer
            </h2>
          </div>
        </Col>
        <Col xs={12} className="box-wrapper">
          <a href="#scroll">
            <div className="button title-sub-2 title-sub-2-box box-wrapper">
              <HiOutlineChevronDown />
            </div>
          </a>
        </Col>
      </Row>
    </Container>
  </div>
);
