import React from "react";
import { Col, Image } from "react-bootstrap";
import "./Card.css";

export const Card = (props) => (
  <Col
    xs={props.xs}
    sm={props.sm}
    md={props.md}
    lg={props.lg}
    xl={props.xl}
    className="card-1 box-wrapper"
  >
    <div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h2 className="title">{props.title}</h2>
      </div>
      <p style={{ margin: 0 }}>{props.children}</p>
    </div>
  </Col>
);

export const IconCard = (props) => (
  <Col
    xs={props.xs}
    sm={props.sm}
    md={props.md}
    lg={props.lg}
    xl={props.xl}
    className="card-icon"
  >
    <div
      className="circle box-wrapper"
      style={{ backgroundColor: `${props.color}`,fontSize: "2.5rem" }}
    >
      {props.icon}
    </div>
    <h3 className="title">{props.title}</h3>
    <p>{props.children}</p>
  </Col>
);

export const ImageCard = (props) => (
  <Col
    xs={props.xs}
    sm={props.sm}
    md={props.md}
    lg={props.lg}
    xl={props.xl}
    className="card-image"
  >
    <div className="card-image-inner">
      <div className="box-wrapper">
        <Image src={props.src} style={{ maxHeight: "75px", maxWidth: "95%" }} />
      </div>
      <br />
      <h4 className="title margin-small">{props.title}</h4>
      <p className="card-image-desc">{props.description}</p>
      <p className="card-image-text">{props.children}</p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="circle-small" />
        <div className="circle-small" style={{ backgroundColor: "#880088" }} />
        <div className="circle-small" style={{ backgroundColor: "#AA0000" }} />
        <p className="card-image-tech">{props.tech}</p>
      </div>
    </div>
  </Col>
);
