import React from "react";
import { Container, Row } from "react-bootstrap";
import { ImageCard } from "./framework/Card.js";
import { Title } from "./framework/Title.js";

import prometheus from "../images/prometheus.png";
import python from "../images/python.png";
import react from "../images/react.png";
import disso from "../images/disso.png";
import airflow from "../images/airflow.png";

import masters from "../docs/30005931_Can_Yetismis.pdf";
import bachelors from "../docs/4278164_Can_Yetismis.pdf";

export const Projects = () => (
  <div
    style={{
      backgroundColor: "#f5f5f5",
      paddingTop: "100px",
      paddingBottom: "120px",
    }}
  >
    <Container>
      <Row style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
        <Title>Projects</Title>
        <ImageCard
          xs={12}
          md={6}
          xl={4}
          src={prometheus}
          title="Monitoring Framework"
          description="Work project"
          tech="Python, Prometheus, Grafana, ROS"
        >
          Monitoring of a ROS infrastructure of WasteAnt GmbH using Prometheus
          and Grafana. I have coded a framework that takes in a configuration
          file from ROS and generates dashboards for Grafana, as well as alert
          rules for Prometheus.
        </ImageCard>
        <ImageCard
          xs={12}
          md={6}
          xl={4}
          src={airflow}
          title="WMSs / Airflow"
          description="Master's thesis"
          tech="Python, Airflow, Ubuntu, RabbitMQ, Celery"
        >
          A PoC for WasteAnt GmbH's image processing pipeline infrastructure,
          using a workflow management system (WMS). I used Airflow to create a
          mock scenario, replicating company infrastructure. You can view my
          Master's thesis by clicking
          <a href={masters} target="_blank" rel="noopener noreferrer">
            {" "}
            here.
          </a>
        </ImageCard>
        <ImageCard
          xs={12}
          md={6}
          xl={4}
          src="https://www.nfcread.com/uploads/logo.png"
          title="SDK Maintenance"
          description="Work project"
          tech="Java, Android, Maven, Gradle"
        >
          Worked in this task as a part of a contract in between BE1 Consultancy
          Corp. and Sanalogi Inc. My duties involved maintaining and adding
          features to the existing Android SDK of{" "}
          <a
            href="https://nfcread.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            NFCRead
          </a>{" "}
          library and providing technical support to various clients.
        </ImageCard>
        <ImageCard
          xs={12}
          md={6}
          xl={4}
          title="Face Recognition"
          src={python}
          description="Work project"
          tech="Python, Django, Docker"
        >
          A back-end service developed for NFCRead, that compares the biometric
          photos with the selfies obtained from the end user. The algorithm has
          been developed using Python, and two publicly available libraries:{" "}
          <a
            href="https://pypi.org/project/face-recognition/"
            target="_blank"
            rel="noopener noreferrer"
          >
            face-recognition
          </a>{" "}
          and{" "}
          <a
            href="https://pypi.org/project/deepface/"
            target="_blank"
            rel="noopener noreferrer"
          >
            DeepFace{" "}
          </a>
          .
        </ImageCard>
        <ImageCard
          xs={12}
          md={6}
          xl={4}
          src={react}
          title="React.JS"
          description="Work and Personal projects"
          tech="Javascript, HTML, CSS, React.JS"
        >
          React framework is used as the primary tool for creating UI's
          throughout the various projects in BE1 Consultancy. For example{" "}
          <a
            href="https://www.be1consultancy.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            BE1 Consultancy website{" "}
          </a>{" "}
          using React and React-Bootstrap. I have also designed this very
          website you're seeing right now with these frameworks too!
        </ImageCard>

        <ImageCard
          xs={12}
          md={6}
          xl={4}
          src={disso}
          title="Hyper-Heuristics"
          description="Bachelor's thesis"
          tech="Java, Windows"
        >
          I did my bachelor's thesis on{" "}
          <a
            href="https://pdfs.semanticscholar.org/82a7/30c61adb76347da57af95ae12945e0c5e5be.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            heuristic algorithms
          </a>
          , in particular a specific subset of heuristic algorithms called{" "}
          <a
            href="https://www.researchgate.net/publication/220571729_A_comprehensive_analysis_of_hyper-heuristics"
            target="_blank"
            rel="noopener noreferrer"
          >
            hyper-heuristics
          </a>
          , to develop a hyper-heuristic approach to counter the wind farm
          layout optimization problem. You can view my Bachelor's thesis by
          clicking
          <a href={bachelors} target="_blank" rel="noopener noreferrer">
            {" "}
            here.
          </a>
        </ImageCard>
      </Row>
    </Container>
  </div>
);
