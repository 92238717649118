import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Title } from "./framework/Title.js";

import { IoLogoLinkedin } from "react-icons/io";
import { IoIosCode } from "react-icons/io";
import { IoMdMail } from "react-icons/io";
import { IoIosDocument } from "react-icons/io";
import cv from "../docs/can_yetismis_cv.pdf";
import "./Contact.css";

export const Contact = () => (
  <div style={{ backgroundColor: "#252934" }}>
    <svg
      preserveAspectRatio="none"
      viewBox="0 0 100 102"
      height="75"
      width="100%"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: "-1px" }}
    >
      <path
        d="M0 0 L50 100 L100 0 Z"
        fill="rgb(245, 245, 245)"
        stroke="rgb(245, 245, 245)"
      ></path>
    </svg>
    <Container
      style={{
        paddingTop: "100px",
        paddingBottom: "120px",
      }}
    >
      <Row>
        <Title color="beige" marginBottom="1rem">
          Contact
        </Title>
        <Col xs={12} className="center-items">
          <span style={{ margin: "auto", display: "table", minWidth: "25%" }}>
            <a
              href="https://www.linkedin.com/in/can-yetismis-14943914b"
              target="_blank"
              rel="noopener noreferrer"
              className="text-colour"
            >
              <IoLogoLinkedin className="logo-size" /> LinkedIn
            </a>
            <br />
            <br />
            <a
              href="https://github.com/canyetismis"
              target="_blank"
              rel="noopener noreferrer"
              className="text-colour"
            >
              <IoIosCode className="logo-size" /> GitHub
            </a>
            <br />
            <br />
            <a href="mailto:can.yetismis@gmail.com" className="text-colour">
              <IoMdMail className="logo-size" /> can.yetismis@gmail.com
            </a>
            <br />
            <br />
            <a
              href={cv}
              target="_blank"
              rel="noopener noreferrer"
              className="text-colour"
            >
              <IoIosDocument className="logo-size" /> CV/Resume
            </a>
          </span>
        </Col>
      </Row>
    </Container>
  </div>
);
