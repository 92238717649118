import React from "react";
import "./Header.css";
import { Container, Row, Col } from "react-bootstrap";
import { MdArrowBack } from "react-icons/md";

export const NotFound = () => (
  <div className="background">
    <Container className="wrapper">
      <Row>
        <Col
          xs={12}
          className="box-wrapper"
          style={{
            textAlign: "center",
          }}
        >
          <div>
            <h1 className="title-main title-desktop title-tablet">404</h1>
            <h2 className="title-sub">
              Cannot find what you are looking for :(
            </h2>
          </div>
        </Col>
        <Col xs={12} className="box-wrapper">
          <a href="/">
            <div className="button title-sub-2-box box-wrapper">
              <MdArrowBack className="title-sub-2" />
            </div>
          </a>
        </Col>
      </Row>
    </Container>
  </div>
);
